import React, { useContext } from "react";
     import styled, { keyframes } from "styled-components";
     import Flex from "../../../components/Box/Flex";
     import { LogoIcon, LogoWithTextIcon, HamburgerIcon, HamburgerCloseIcon, LogoIcon as LogoWithText } from "../../../components/Svg";
     import { MenuContext } from "../context";
     
     interface Props {
       isDark: boolean;
       href: string;
       isPushed: boolean;
       togglePush: () => void;
     }
     
     const blink = keyframes`
       0%,  100% { transform: scaleY(1); }
       50% { transform:  scaleY(0.1); }
     `;
     
     const StyledLink = styled("a")`
       display: flex;
       align-items: center;
       .mobile-icon {
         width: 50px;
         ${({ theme }) => theme.mediaQueries.nav} {
           display: none;
         }
       }
       .desktop-icon {
        width: 50px;
        display: none;
        ${({ theme }) => theme.mediaQueries.nav} {
          display: block;
        }
      }
       .eye {
         animation-delay: 20ms;
       }
       &:hover {
         .eye {
           transform-origin: center 60%;
           animation-name: ${blink};
           animation-duration: 350ms;
           animation-iteration-count: 1;
         }
       }
     `;
     
     const Logo: React.FC<Props> = ({ isPushed, togglePush, isDark, href }) => {
       const { linkComponent } = useContext(MenuContext);
       const isAbsoluteUrl = href.startsWith("http");
       const innerLogo = (
        <>
          <LogoIcon className="mobile-icon" />
          <LogoWithTextIcon className="desktop-icon" />
        </>
      );
     
       return (
             <Flex>
           {isAbsoluteUrl ? (
             <StyledLink as="a" href={'https://app.gangverse.club'} aria-label="Gangverse Club">
               {innerLogo}
             </StyledLink>
           ) : (
             <StyledLink href={'https://app.gangverse.club'} as={linkComponent} aria-label="Gangverse Club">
               {innerLogo}
             </StyledLink>
           )}
         </Flex>
       );
     };
     
     export default React.memo(Logo, (prev, next) => prev.isPushed === next.isPushed && prev.isDark === next.isDark);
     
