import { Language } from "../LangSelector/types";
import { FooterLinkType, SocialLinkType } from "./types";

export const footerLinks: FooterLinkType[] = [


  {
    label: "Developers",
    items: [

    ],
  },
];

export const socials: SocialLinkType[] = [
  {
    label: "Twitter",
    icon: "Twitter",
    href: "https://twitter.com/gangverse",
  },
  {
    label: "Instagram",
    icon: "Instagram",
    href: "https://www.instagram.com/gangversenft/",
  },
  {
    label: "Discord",
    icon: "Discord",
    href: "https://discord.gg/u9NJ8GBPXA",
  },
  {
    label: "Github",
    icon: "Github",
    href: "https://github.com/gangverse",
  },

];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
