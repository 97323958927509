import { serializeTokens } from './tokens'
import { SerializedNftFarmGangverseConfig } from './types'
import { ChainId } from '@linbingdefi/sdk'

const serializedTokens = serializeTokens()

const nftFarmsGangverse: SerializedNftFarmGangverseConfig[] = [
  

{
    pid: 1,
    lpSymbol: 'NOT STAKE',
    nftAddresses: {
      25: '0xC376D7a55a3Fb6FA0B12D90fd96FeFB0d96702bF',
    },
    contractAddresses: {
      25: '0x33EFAA02B3F80F20676C478d3A1EDA1A47ECA0a3',
    },
    
    tokenPerBlock: '0.005',
    participantThreshold: 1000,
    isFinished: true,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://gangverse.club',
    banner: "/images/stakenft/banner/darkside.png",
    avatar: "/images/stakenft/logo/darkside.png",
  },
  {
    pid: 2,
    lpSymbol: 'NOT STAKE',
    nftAddresses: {
      25: '0xaBaa122B9E6B64FCBDD55A03eEb8729220F9C589',
    },
    contractAddresses: {
      25: '0x6fAa4Cff66684Df82460C0eD6c53398E38C8b334',
    },
    
    tokenPerBlock: '0.005',
    participantThreshold: 8888,
    isFinished: true,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://gangverse.club',
    banner: "/images/stakenft/banner/gangverse.png",
    avatar: "/images/stakenft/logo/gangverse.gif",
  },
  {
    pid: 3,
    lpSymbol: 'NOT STAKE',
    nftAddresses: {
      25: '0xa4cbC591c6D20a1B5CE35e52779191F4A35d62D1',
    },
    contractAddresses: {
      25: '0xD75d838Bd5F0d86aD614bA47a62B9d57F81632Fd',
    },
    
    tokenPerBlock: '0.005',
    participantThreshold: 3939,
    isFinished: true,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://yurosako.com/',
    banner: "/images/stakenft/banner/yurosako.jpg",
    avatar: "/images/stakenft/logo/yurosako.jpg",
  },
  {
    pid: 4,
    lpSymbol: 'NOT STAKE',
    nftAddresses: {
      25: '0x770FA15c43b84F61434321F5167814b64790E6Fa',
    },
    contractAddresses: {
      25: '0xE9532F46Cf3adFA629c5b7b4a7729ecB3f908aF2',
    },
    
    tokenPerBlock: '0.005',
    participantThreshold: 1630,
    isFinished: true,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://www.lifeofhel.xyz/',
    banner: "/images/stakenft/banner/loh.jpg",
    avatar: "/images/stakenft/logo/loh.jpg",
  },
  {
    pid: 5,
    lpSymbol: 'NOT STAKE',
    nftAddresses: {
      25: '0x9cFB6b1F67abFe4d02d99DbA00A25F8F82E0Df63',
    },
    contractAddresses: {
      25: '0x0E612fd2a85829d7FEc2f736701f0284A4894809',
    },
    
    tokenPerBlock: '0.005',
    participantThreshold: 4040,
    isFinished: true,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://discord.gg/gTjfjJBtTb',
    banner: "/images/stakenft/banner/mous.png",
    avatar: "/images/stakenft/logo/mous.png",
  },
  {
    pid: 6,
    lpSymbol: 'GNG DARKSIDE 🚀',
    nftAddresses: {
      25: '0xC376D7a55a3Fb6FA0B12D90fd96FeFB0d96702bF',
    },
    contractAddresses: {
      25: '0x5c9D31dECfb57A8B580a51A2CDB7226854f65662',
    },
    
    tokenPerBlock: '0.1',
    participantThreshold: 1000,
    isFinished: false,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://gangverse.club',
    banner: "/images/stakenft/banner/darkside.jpg",
    avatar: "/images/stakenft/logo/darkside.png",
  },
  {
    pid: 7,
    lpSymbol: 'YUROSAKO 🚀',
    nftAddresses: {
      25: '0xa4cbC591c6D20a1B5CE35e52779191F4A35d62D1',
    },
    contractAddresses: {
      25: '0x3E4a58492051f561171b007EA24411e1F41979F2',
    },
    
    tokenPerBlock: '0.070',
    participantThreshold: 1000,
    isFinished: false,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://yurosako.com/',
    banner: "/images/stakenft/banner/yurosako.png",
    avatar: "/images/stakenft/logo/yurosako.jpg",
  },
  {
    pid: 8,
    lpSymbol: 'LIFE OF HEL 🚀',
    nftAddresses: {
      25: '0x770FA15c43b84F61434321F5167814b64790E6Fa',
    },
    contractAddresses: {
      25: '0x83200F8b807B1A48012c45202CB33fde49A950A6',
    },
    
    tokenPerBlock: '0.075',
    participantThreshold: 1000,
    isFinished: false,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://www.lifeofhel.xyz/',
    banner: "/images/stakenft/banner/loh.png",
    avatar: "/images/stakenft/logo/loh.jpg",
  },
  {
    pid: 9,
    lpSymbol: 'CRONONYMOUS 🚀',
    nftAddresses: {
      25: '0x9cFB6b1F67abFe4d02d99DbA00A25F8F82E0Df63',
    },
    contractAddresses: {
      25: '0x880eFDe422992899c6E33D9091804E936755E76D',
    },
    
    tokenPerBlock: '0.085',
    participantThreshold: 1000,
    isFinished: false,
    earningToken: serializedTokens.wcro,
    // sideRewards: [
    // {token: 'CRO', percentage: 0},
    //  ],
    // supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://discord.gg/gTjfjJBtTb',
    banner: "/images/stakenft/banner/mous.jpg",
    avatar: "/images/stakenft/logo/mous.png",
  },
  
  

].filter((f) => !!f.nftAddresses[ChainId.CRONOS])

export default nftFarmsGangverse
