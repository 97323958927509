import Metamask from "../../components/Svg/Icons/Metamask";
import Okx from "../../components/Svg/Icons/Okx";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import CryptoCom from "../../components/Svg/Icons/CryptoCom";

import { Config, ConnectorNames } from "./types";

const connectors: Config[] = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    priority: 1,
  },
  {
    title: "DeFi Wallet",
    icon: CryptoCom,
    connectorId: ConnectorNames.Injected,
    priority: 2,
  },
  {
    title: "WalletConnect",
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
    priority: 3,
  },
  {
    title: "OKX Wallet",
    icon: Okx,
    connectorId: ConnectorNames.OKX,
    priority: 4,
  },
];

export default connectors;
export const connectorLocalStorageKey = "connectorIdv2";
export const walletLocalStorageKey = "wallet";
